<template>
  <wp-items-edit
    content-type="research-articles"
    content-type-singular="research-article"
    content-name="Research articles"
    content-name-singular="Research article"
  />
</template>

<script>
import WpItemsEdit from '@/views/wp-item/wp-items-edit/WpItemsEdit.vue'

export default {
  components: {
    WpItemsEdit,
  },
}
</script>

<style scoped>

</style>
